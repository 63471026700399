import React, { useState, useEffect,createRef  } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation  } from 'react-router-dom'


import './App.css';

import NavbarComp from './components/navabar';
import NavbarFooterComp from "./components/navbar_footer"
import SliderShowComp from './components/slidershow';
import CarouselComp from './components/carousel';
import NewsComp from './components/news';
import SinglePageComp from './components/single_page'
import BrandsComp from './components/brands'
import ArticlesComp from './components/articles';

// import { Carousel } from 'bootstrap';
import * as actionTypes from "./const/actionTypes";
import {
  useSelector,
  useDispatch
} from 'react-redux'

import {} from "react-icons"
import axios from 'axios';



function App()  {

  const history = useHistory();
  const location=useLocation();
  //console.log(location.pathname);

   const baseApiUrl="https://old.elleerre.net/data/";
  //const baseApiUrl="http://localhost:3000/data/";
  const initialState = 0;
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState(0);
  const [menuFetch, setMenuFetch] = useState([]);
  const [slidershowFetch, setSlidershowFetch] = useState([]);
  const [articlesFetch, setarticlesFetch] = useState([]);
  const [pagesFetch, setpagesFetch] = useState([]);
  const [brandsFetch, setbrandsFetch] = useState([]);
  const [page, setPage] = React.useState(1);

 


  useEffect(() => {
    callMenu();
    callSlidershow();
    callArticles();
    callPages();
    callBrands();
      // console.log(menuFetch);
  }, [] );


  const callMenu = () => {
    
    try {
    axios.get(baseApiUrl + "menu.json")
      .then((res) => {
        
        dispatch({
          type: actionTypes.FETCH_MENU,
          payload: setMenuFetch(res.data)
        });
        
  
      })
    }
  catch (error) {
          const err = error;
          if (err.response) {
             console.log(err.response.status)
             console.log(err.response.data)
          }
          this.handleAxiosError(error)
       }
  }
  
   const callSlidershow = () => {
    try {
    axios.get(baseApiUrl + "slidershow.json")
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: actionTypes.FETCH_SLIDERS,
          payload: setSlidershowFetch(res.data)
        });      
  
  
      })
    }
  catch (error) {
          const err = error;
          if (err.response) {
             console.log(err.response.status)
             console.log(err.response.data)
          }
          this.handleAxiosError(error)
       }
  }
  
   const callArticles = () => {
    try {
    axios.get(baseApiUrl + "articles.json")
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: actionTypes.FETCH_SLIDERS,
          payload: setarticlesFetch(res.data)
        });      
  
  
      })
    }
  catch (error) {
          const err = error;
          if (err.response) {
             console.log(err.response.status)
             console.log(err.response.data)
          }
          this.handleAxiosError(error)
       }
  }

  const callPages = () => {
    try {
    axios.get(baseApiUrl + "news.json")
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: actionTypes.FETCH_SLIDERS,
          payload: setpagesFetch(res.data)
        });      
  
  
      })
    }
  catch (error) {
          const err = error;
          if (err.response) {
             console.log(err.response.status)
             console.log(err.response.data)
          }
          this.handleAxiosError(error)
       }
  } 
  
  const callBrands = () => {
    try {
    axios.get(baseApiUrl + "brand.json")
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: actionTypes.FETCH_SLIDERS,
          payload: setbrandsFetch(res.data)
        });      
  
  
      })
    }
  catch (error) {
          const err = error;
          if (err.response) {
             console.log(err.response.status)
             console.log(err.response.data)
          }
          this.handleAxiosError(error)
       }
  } 

  let swUrl=location.pathname=="/"||location.pathname=="";
  
// console.log(swUrl)
  return (

<div className="App">
  <nav className="navbar navbar-expand-md bg-dark navbar-dark fixed-top">

  <a className="navbar-brand" href="/">
  <img src="/images/logo.png" alt="Elle Erre" height="50"/>
  </a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span className="navbar-toggler-icon"></span>
  </button>
    <NavbarComp menu={menuFetch}></NavbarComp>
  </nav>  

{
  swUrl?
  <div>
            <div id="demo" className="carousel slide" data-ride="carousel">

            <div className="carousel-inner">
              <SliderShowComp slides={slidershowFetch}></SliderShowComp>
            </div>
          
              <a className="carousel-control-prev" href="#demo" data-slide="prev">
                <span className="carousel-control-prev-icon"></span>
              </a>
              <a className="carousel-control-next" href="#demo" data-slide="next">
                <span className="carousel-control-next-icon"></span>
              </a>
          
            </div>
            <div className="container-fluid my-4">
            <div id="multi-item-example" className="carousel slide carousel-multi-item" data-ride="carousel">
            <div className="controls-top">
                <a className="btn-floating" href="#multi-item-example" data-slide="prev"><i className="btn btn-dark m-2">&lt;</i></a>
                <a className="btn-floating" href="#multi-item-example" data-slide="next"><i className="btn  btn-dark m-2">&gt;</i></a>
            </div>    

            <div className="carousel-inner" role="listbox">
          
                    <CarouselComp slides={articlesFetch}></CarouselComp>
                  </div>
            </div>

            <div className="container-fluid">
            <NewsComp pages={pagesFetch}></NewsComp>
            </div>
            <div className="container-fluid pagecss">
            <h3 className="bg-dark w-100 text-white">MARCHI COMMERCIALIZZATI</h3>
              <BrandsComp brands={brandsFetch} category="all"></BrandsComp>
            </div>

           
      </div>            
 </div>           
            :
            <div   className="pagecss">
            <SinglePageComp news={articlesFetch} articles={pagesFetch} brands={brandsFetch} id={location.pathname}></SinglePageComp>
            </div>
            
}





      <footer className="container-fluid text-center bg-dark text-white w-100 p-5">
      <NavbarFooterComp menu={menuFetch}></NavbarFooterComp>
      <hr/>
           Elle Erre Srl - Via dei Barrocciai, 12 - Zona Industriale EST - 06081 Santa Maria Degli Angeli, Assisi (PG)<br/>
           Tel: 075 8042434 | E-mail: info@elleerre.net | P.IVA 01516570544 | SDI: J6URRTW<br/>
           &copy; 2021 - Elle Erre Srl
           
     </footer>      
</div>

  );


}

export default App;
