import * as actionTypes from "../const/actionTypes";

const initialState = {
    error: null,
    loading: false,
    res: false,

};

const logHandleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SLIDERS:
            return {
                ...state,
                error: null,
                loading: false,
                res: false
            };        
        default:
            return state;        
    }
}

export default logHandleReducer;