
import React, { useState, useEffect } from 'react';




const BrandsComp=(props) => {

  var _brands=[];

  Object.keys(props.brands).forEach(function(key) {
    if (props.category.toLowerCase()==props.brands[key].title.toLowerCase() | props.category.toLowerCase()=="all")
      _brands.push(props.brands[key]);
})

const myBrand=_brands.map((obj)=>{
  
  let brandList=Object.keys(obj.item).map((id)=>{
    //console.log(obj.item[id].img);
    return <div className="card w-brand" keys={obj.item[id].title}><a href={obj.item[id].url}><div class="card-body"><img src={obj.item[id].img} className="card-img-top img-fluid" alt={obj.item[id].alt}/><h5 class="text-darger align-baseline">{obj.item[id].title}</h5></div></a></div>
    
  })
  return (
    <div>
      <h4>{obj.title}</h4>
      <p>{obj.descr}</p>
      <div className="row">
      {brandList}
      </div>
  </div>

  )
});

//console.log(_brands);
  return (
    <div>
{myBrand}
    </div>
    
  )

}

export default BrandsComp;