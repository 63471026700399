
import React, { useState, useEffect } from 'react';




const ArticlesComp=(props) => {

  var _articles=[];
  var cat=props.category.split(",");

  //console.log(cat);
  cat.forEach((kat)=>{
    Object.keys(props.articles).forEach(function(key) {
      if (kat==props.articles[key].id.toString() | kat.toLowerCase()=="all")
      _articles.push(props.articles[key]);
  })
  })



const myArticles=_articles.map((obj)=>{
  
  let articlesList=Object.keys(obj.item).map((id)=>{
    //console.log(obj.item[id].img);
    return <div className="card w-brand" keys={obj.item[id].title}><div class="card-body"><img src={obj.item[id].img} className="card-img-top img-fluid" alt={obj.item[id].alt}/><h6 class="text-danger align-baseline">{obj.item[id].title}</h6></div><div className="card-footer"><a href={obj.item[id].url} className="btn btn-dark w-100">Scheda tecnica</a></div></div>
    
  })
  return (
    <div>
      <h4>{obj.category}</h4>
      <p>{obj.subject}</p>
      <div className="row">
      {articlesList}
      </div>
  </div>

  )
});

  return (
    <div>
{myArticles}
    </div>
    
  )

}

export default ArticlesComp;