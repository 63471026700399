import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';


const NewsComp=(props) => {
    var _news = [];

    Object.keys(props.pages).forEach(function(key) {
        _news.push(props.pages[key]);
        
    })
    
    const myNews=_news.map((obj)=>{
        
        let cat="";
        let itemList=Object.keys(obj.item).map((id)=>{
            try {
                cat=obj.item[id].descr.match(/{#(.*?)#}/i)[1];
            }
            catch {}
        if (obj.item[id].status=="Home") {
            return (
                <div className="card col-md-12 col-sm-12" keys={obj.item[id].id}>
                <h4>{obj.item[id].title}</h4>
                <p>{parse(obj.item[id].descr.replace("{#"+cat+"#}",""))}</p>
                </div>
             )
        }  
        else {
        }     

});
    if (obj.status=="Home") {
        return (
            <div className="text-justify">
                <h2>{obj.title}</h2>
                <div className="card-deck row">
                {itemList}
            </div>
            </div>
        )
    }
    else {
        
    }
    })
        return (
            <div>
  
  {myNews}

            </div>
                
            
        )

}

export default NewsComp