import React, { useState, useEffect } from 'react';




const NavbarComp=(props) => {

  // const handleClick = (myLink) => () => {
  //   window.location.href=myLink;
  // }
var _menu = [];

    Object.keys(props.menu).forEach(function(key) {
      _menu.push(props.menu[key]);
    });


  const myMenu=_menu.map((obj)=>{

    let itemList=Object.keys(obj.submenu).map((id)=>{
      
      return <a className="dropdown-item"  href={obj.submenu[id].url} key={obj.submenu[id].item}>{obj.submenu[id].item}</a>
    
    })

    return (

      <li  className="nav-item dropdown" key={obj.id.toString()}>
      <a className="nav-link dropdown-toggle" href={obj.url} id="navbardrop" data-toggle="dropdown" key={obj.id.toString()+obj.id.title}>
      {obj.title} 
    </a>
    <div className="dropdown-menu m-auto">
        {itemList}
    </div>    
      </li>    

    );
  });


 
  return (
    <div className="collapse navbar-collapse" id="collapsibleNavbar">
      <ul className="navbar-nav">
        {myMenu}
      </ul>
    </div>
  );
}



export default NavbarComp;



