import React, { useState, useEffect } from 'react';


const CarouselComp=(props) => {
    var _card = [];

    Object.keys(props.slides).forEach(function(key) {
        _card.push(props.slides[key]);
        
    })
    const myCard=_card.map((obj)=>{
         
        let itemList=Object.keys(obj.item).map((id)=>{
                            
            return (
                <div className="col-md-2" keys={obj.item[id].id}>
                <div className="card">
                <img className="card-img-top card-h100" src={obj.item[id].img} alt={obj.item[id].alt}/>
                <div className="card-body">
                    <h6 className="card-title">{obj.item[id].title}</h6>
                    {/* <p className="card-text">{obj.item[id].descr}</p> */}
                    <a href={obj.item[id].url} className="btn btn-dark w-100">Scheda tecnica</a>
                </div>
                </div>
            </div>)
});
const active=obj.id==="1";
        return (
            <div className={active?"carousel-item active":"carousel-item"} key={obj.id}>
                <h4>{obj.title}</h4>
            <div className="row">
                {itemList}
            </div>
            </div>
        )
    })
        return (
            <div className="double_card">
                {myCard}
            </div>
                
            
        )

}

export default CarouselComp