import React, { useState, useEffect } from 'react';




const SliderShowComp=(props) => {

    var _slides = [];

    Object.keys(props.slides).forEach(function(key) {
        _slides.push(props.slides[key]);
    });

    //console.log(_slides);



    const myClass=_slides.map((obj)=>{
        //console.log(obj.id);
        const active=obj.id.toString()==="1";
        return (
            <div className={active?"carousel-item active":"carousel-item"} key={obj.id}>
            {/* {console.log(obj.img)} */}
        <img src={obj.img} alt="siderurgia"/>
            <div className="carousel-caption  text-left">
              <h1>{obj.title}</h1>
              <p>{obj.descr}</p>
              <p><a className="btn btn-lg btn-danger" href={obj.url} role="button">informati</a></p>
            </div>
      </div>
        );
        
    })


    


    return myClass
}

export default SliderShowComp;