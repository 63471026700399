import React, { useState, useEffect } from 'react';

const NavbarFooterComp=(props) => {

    // const handleClick = (myLink) => () => {
    //   window.location.href=myLink;
    // }
  var _menuFooter = [];
  
      Object.keys(props.menu).forEach(function(key) {
        _menuFooter.push(props.menu[key]);
      });
  

    const myMenufooter=_menuFooter.map((obj)=>{
  
      let itemList=Object.keys(obj.submenu).map((id)=>{
  
        return <a className="text-decoration-none bg-dark"  href={obj.submenu[id].url} key={obj.submenu[id].item}>{obj.submenu[id].item}</a>
      
      })
  //console.log(_menu);
      return (
  
        <li className="list-group-item w-100 bg-dark"  key={obj.id.toString()}>
        <a className="list-group-item list-group-item-action list-group-item-dark text-decoration-none" href={obj.url}  key={obj.id.toString()+obj.id.title}>
        {obj.title} 
      </a>
      <ul className="list-group text-left bg-dark w-100">
          {itemList}
      </ul>    
        </li>    
  
      );
    });
  
  
   
    return (
      <div className="text-left bg-dark w-100">
        
          {myMenufooter}

      </div>
    );
  }
export default NavbarFooterComp;