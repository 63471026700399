import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import ArticlesComp from './articles';
//import JsxParser from 'react-jsx-parser'
// import { Parser, ProcessNodeDefinitions } from 'html-to-react';

const SinglePageComp=(props) => {
    var _news = [];
    var _pages = [];
    var _brand=[];
    var myItem=[];
    var myBrand=[];

    let cat="";
    let swBrand=props.id.indexOf("brand")==-1;
    // carico le news che sono singole pagine lanciate dal menu - navbar
    Object.keys(props.news).forEach(function(key) {
        _news.push(props.news[key]);
        
    })
    
    // carico gli articoli che possono essere gli articoli di magazzino oppure delle pagine web raggruppate per categoria
    Object.keys(props.articles).forEach(function(key) {
        _pages.push(props.articles[key]);
        
    });

    // carico i brands
    Object.keys(props.brands).forEach(function(key) {
            
            _brand.push(props.brands[key]);

        
    }); 

    


    _news.map( (items)=>{

        let myCat=props.id.toLowerCase().replace("/categoria/").replace("undefined","");
        
        if (typeof(props.id)=="string") {
            if (props.id.indexOf("categoria")>0 & items.title.toLowerCase()==myCat.replaceAll("_"," ")) {
                myItem=items;
                cat=items.id.toString();
                //console.log(myItem);
            }

        }

        Object.keys(items.item).map(id=>{
            // console.log(items.item[id].url);

            if (items.item[id].url==props.id & props.id.indexOf("categoria")<0) {
                myItem=items.item[id];
                cat=items.id.toString();
                // console.log(items.id)
            }
        })           
        
    });

    _brand.map( (brand)=>{
        var app=props.id.split('/');
        //console.log(app[app.length-1]);
        Object.keys(brand.item).map(id=>{ 
            if (brand.item[id].title.toLowerCase().indexOf(app[app.length-1])>-1) 
            {
            
                myBrand=brand.item[id];
            }
        })

    }); 

    _pages.map( (pgs)=>{
        Object.keys(pgs.item).map(id=>{
            //console.log(pgs.subject.toLowerCase()+'_'+props.id);
            let app=props.id.split("-");
            if (app.length>1) {
                let title=app[1].replace("_"," ");
                //console.log(title)
                if (pgs.item[id].title.toLowerCase()==title) {
                    myItem=pgs.item[id];
                }                
            }
        })

    
        
    });    

    

    let isString=typeof(myItem.descr)=="string";
    //console.log(isString)
    let imgExists=typeof(myItem.img)!=="undefined";

    
if (isString) {
    try {
        cat=myItem.descr.match(/{#(.*?)#}/i)[1];
    }
    catch {}
    //console.log(cat);
}


    // console.log(typeof(myItem.img))

    //console.log(myItem);
    return (
        <div className="container-fluid">
        <div className="text-left p-4"> 
            <div className="row">
                <div className={imgExists?"col-md-8 col-sm-12":"col-md-12 col-sm-12"}>
                    <h2>{myItem.title}</h2>
                    {isString?parse(myItem.descr.replace("{#" + cat + "#}","")):''}
                </div>
                {imgExists?
                                <div className="col-md-4 col-sm-12">
                                <img src={myItem.img} className="img-fluid w-100" />
                            </div>
                :""}

            </div>
       </div>
       {
        swBrand?
        <ArticlesComp articles={props.news} category={cat}></ArticlesComp>
        :
        <div className="brand"> 
            <img src={myBrand.img} alt={myBrand.title}/><h1>{myBrand.title}</h1>
            {myBrand.descr}
        </div>
       }
      
       
       
       </div>
    )
}

export default SinglePageComp
